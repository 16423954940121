import { useMemo } from "react";
import { NextLink } from "@/components/common/styles";
import {
  Card,
  Box,
  Typography,
  Link,
  LinearProgress,
  useTheme,
  Button,
  Divider,
  Stack,
  Skeleton,
} from "@mui/material";
import useSubscriptionStats from "@/hooks/useSubscriptionStats";
import AllInclusive from "@mui/icons-material/AllInclusive";
import { useSession } from "../../security/RouteGuards";
import { gbToBytes } from "../../utils/conversionUtils";
import { userReadableBytes } from "../../utils/textUtils";
import { FlexCentered } from "../common/styles";
import { getBandwidthUsageState } from "../../utils/getBandwidthUsageState";
import {
  DashBox,
  DashedHolder,
  LightBox,
  MainBox,
  UsageHolder,
} from "./styles";
import { formCustomizePageHref } from "@/utils/formHref";
import useIntercom from "@/hooks/useIntercom";
import InfoCard from "@/ui/InfoCard";
import useSWR from "swr";
import { ProxyConfigurationType } from "@/types/ProxyConfigurationTypes";
import { fetcher } from "@/utils/fetcher";
import { formBackendURL } from "@/utils/formURL";
import { articles } from "@/utils/intercom";

type BandwidthUsageVariants = "full" | "compact";
interface BandwidthUsageProps {
  variant?: BandwidthUsageVariants;
}

function PercentageUsage({
  total,
  value,
  bandwidthLimit,
  variant,
  usageState,
}: {
  total: number;
  value: number;
  bandwidthLimit: number;
  variant: BandwidthUsageVariants;
  usageState: "error" | "warning" | undefined;
}) {
  if (bandwidthLimit === 0) {
    if (variant === "full") {
      return (
        <FlexCentered color="text.secondary">
          <AllInclusive
            color="inherit"
            fontWeight={"bold"}
            fontSize="small"
            sx={{ mr: 2 }}
          />
          <Typography variant="body2" fontWeight={"bold"}>
            GB
          </Typography>
        </FlexCentered>
      );
    } else {
      return usageState === "error" ? (
        <>
          <Typography variant="caption" component="div">
            Extremely high bandwidth usage.
          </Typography>
          <Typography variant="caption" component="div">
            Your proxy may be throttled.{" "}
          </Typography>
        </>
      ) : (
        <>
          <AllInclusive color="inherit" fontSize="small" sx={{ mr: 2 }} />
          <Typography variant="caption">GB</Typography>
        </>
      );
    }
  }

  if (variant === "full") {
    return (
      <Typography variant="body2" color="text.secondary" fontWeight={"bold"}>
        <span>{value.toFixed(1)}</span>
        <span>% of</span> <span>{userReadableBytes(bandwidthLimit)}</span>{" "}
        <span>Used</span>
      </Typography>
    );
  } else {
    return (
      <Typography variant="caption" color="text.primary">
        <span>{userReadableBytes(total)}</span> <span>of</span>{" "}
        <span>{userReadableBytes(bandwidthLimit)}</span> <span>used</span>
      </Typography>
    );
  }
}

function RemainingUsage({
  subtract,
  bandwidthLimit,
}: {
  subtract: number;
  bandwidthLimit: number;
}) {
  if (bandwidthLimit === 0) {
    return (
      <FlexCentered>
        <AllInclusive sx={{ mr: 2 }} />
        <Typography variant="h6">GB</Typography>
      </FlexCentered>
    );
  } else {
    return (
      <Typography variant="h6" data-testid="remaining-usage">
        {userReadableBytes(bandwidthLimit - subtract)}
      </Typography>
    );
  }
}

export default function BandwidthUsage({
  variant = "full",
}: BandwidthUsageProps) {
  const theme = useTheme();

  const { plan } = useSession();

  const { showChat, showArticle } = useIntercom();

  const aggregateStatsData = useSubscriptionStats();
  const { data, isLoading } = useSWR<ProxyConfigurationType>(
    formBackendURL(`/proxy/config/`),
    fetcher
  );

  const { limit, total, projected, value, valueBuffer, subtract } =
    useMemo(() => {
      const limit = gbToBytes(plan?.bandwidth_limit);
      const total = aggregateStatsData?.bandwidth_total ?? 0;
      const projected = aggregateStatsData?.bandwidth_projected ?? 0;
      const value = (total / limit) * 100;
      const valueBuffer = limit > projected ? (projected / limit) * 100 : 100;
      const subtract = limit > projected ? Math.max(projected, total) : limit;

      return { limit, total, projected, value, valueBuffer, subtract };
    }, [plan, aggregateStatsData]);

  const usageState = getBandwidthUsageState({
    aggregateStatsData,
    plan,
  });

  // In case of unlimited bandwidth, no need to show the widget
  if (limit == 0 && variant === "compact") {
    return null;
  }

  const primaryColor = theme.palette[usageState ?? "primary"].main;

  const showPlaceholder = data && data.is_proxy_used === false;
  const showLoader = isLoading;

  return variant === "full" ? (
    <Card sx={{ p: 3, height: "100%" }}>
      <Stack sx={{ height: "100%" }} justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" color="text.primary">
            Bandwidth Usage
          </Typography>
          <Link
            underline="hover"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              showArticle(articles.bandwidthUsage);
            }}
          >
            <Typography variant="body1"> Learn More</Typography>
          </Link>
        </Stack>

        {showLoader ? (
          <Skeleton
            data-testid="bandwidth-usage-loader"
            variant="rectangular"
            height={150}
          />
        ) : showPlaceholder ? (
          <InfoCard
            testId="bandwidth-usage-placeholder"
            description="No data to show"
            elevation={0}
            backgroundColor="grey.100"
            imageSrc="/scatterPlot"
            imageAlt="Empty Scatter Plot"
            visualSize={50}
          >
            <NextLink href="/proxy/list" passHref>
              <Link component="span" underline="always" color="primary.main">
                Go to Proxy list
              </Link>
            </NextLink>
          </InfoCard>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <Box sx={{ flex: 1 }}>
                <LinearProgress
                  color={usageState ?? "primary"}
                  variant="buffer"
                  valueBuffer={limit === 0 ? 0 : valueBuffer}
                  value={limit === 0 ? 0 : value < 100 ? value : 100}
                />
              </Box>
              <Box sx={{ pl: 2 }}>
                <PercentageUsage
                  total={total}
                  value={value}
                  bandwidthLimit={limit}
                  variant={variant}
                  usageState={usageState}
                />
              </Box>
            </Box>

            <UsageHolder>
              <Box>
                <FlexCentered>
                  <MainBox primaryColor={primaryColor} />
                  <Typography variant="caption" color="text.secondary">
                    Actual Usage
                  </Typography>
                </FlexCentered>
                <Typography variant="h6" data-testid="actual-usage">
                  {userReadableBytes(total)}
                </Typography>
              </Box>
              <Box sx={{ ml: 3 }}>
                <FlexCentered>
                  <LightBox primaryColor={primaryColor} />
                  <Typography variant="caption" color="text.secondary">
                    Projected Usage
                  </Typography>
                </FlexCentered>
                <Typography variant="h6" data-testid="projected-usage">
                  {userReadableBytes(projected)}
                </Typography>
              </Box>

              <Box sx={{ ml: 3 }}>
                <FlexCentered>
                  <DashedHolder>
                    <DashBox primaryColor={primaryColor} />
                    <DashBox primaryColor={primaryColor} />
                    <DashBox primaryColor={primaryColor} />
                  </DashedHolder>
                  <Typography variant="caption" color="text.secondary">
                    Projected Remaining
                  </Typography>
                </FlexCentered>
                <RemainingUsage subtract={subtract} bandwidthLimit={limit} />
              </Box>
            </UsageHolder>
          </>
        )}
      </Stack>
    </Card>
  ) : (
    <>
      <Divider />
      <Box sx={{ my: 3 }}>
        <Typography variant="overline" color="text.primary">
          BANDWIDTH USAGE
        </Typography>
        <Box sx={{ my: 1 }}>
          <LinearProgress
            color={usageState ?? "primary"}
            variant={limit === 0 ? "indeterminate" : "determinate"}
            valueBuffer={limit === 0 ? 0 : valueBuffer}
            value={limit === 0 ? 0 : value < 100 ? value : 100}
          />
        </Box>
        <Box>
          <PercentageUsage
            total={total}
            value={value}
            bandwidthLimit={limit}
            variant={variant}
            usageState={usageState}
          />
        </Box>
        <Box sx={{ my: 3 }}>
          {limit === 0 ? (
            <Button
              size="small"
              variant="outlined"
              color={usageState ?? "primary"}
              onClick={() => {
                showChat();
              }}
            >
              Contact Us
            </Button>
          ) : (
            <NextLink
              href={formCustomizePageHref({
                source: "nav-bar-bottom",
                showCustomizeSections: ["bandwidth-plan"],
              })}
              passHref
            >
              <Button
                size="small"
                variant="outlined"
                color={usageState ?? "primary"}
              >
                Upgrade
              </Button>
            </NextLink>
          )}
        </Box>
      </Box>
    </>
  );
}
