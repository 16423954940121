import { AggregateStatsType } from "../types/AggregateStatsType";
import { PlanType } from "../types/PlanTypes";
import { gbToBytes } from "./conversionUtils";

interface IProps {
  aggregateStatsData: AggregateStatsType | undefined;
  plan: PlanType | undefined;
}

export const getBandwidthUsageState = ({
  aggregateStatsData,
  plan,
}: IProps) => {
  const { bandwidth_total, bandwidth_projected } = aggregateStatsData || {};
  const { bandwidth_limit } = plan || {};

  const bandwidthTotal = bandwidth_total ?? 0;
  const bandwidthProjected = bandwidth_projected ?? 0;
  const bandwidthLimit = gbToBytes(bandwidth_limit ?? 0);

  if (bandwidthLimit === 0) return;

  if (bandwidthTotal / bandwidthLimit >= 0.99) return "error";

  if (
    bandwidthProjected > bandwidthLimit &&
    bandwidthTotal > 0.5 * bandwidthLimit
  )
    return "warning";

  return;
};
