import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { FlexBetween } from "../common/styles";

export const MainBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== "primaryColor",
})<{
  primaryColor?: string;
}>(({ theme, primaryColor }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  marginRight: theme.spacing(2),
  background: primaryColor ?? theme.palette.primary.main,
}));

export const LightBox = styled(MainBox)(() => ({
  opacity: 0.3,
}));

export const DashBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== "primaryColor",
})<{
  primaryColor?: string;
}>(({ theme, primaryColor }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  background: primaryColor ?? theme.palette.primary.main,
  opacity: 0.3,
}));

export const DashedHolder = styled(FlexBetween)(({ theme }) => ({
  alignItems: "center",
  width: theme.spacing(4),
  marginRight: theme.spacing(2),
}));

export const UsageHolder = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
